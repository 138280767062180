import { useEffect, useState } from "react";
import { validarEmail } from "../../utils/validators";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import "./_variants.css"
import "./recovery-pass.css"
import axios from "axios";
import { api } from "../../services";
import Loading from "../../components/loading";
import { toast } from "react-toastify";

const firstColor = "#f17f38"

const ConfirmAccountPage = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email'); 
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);



    useEffect(() => {
        console.log(email)
        if(!email || !validarEmail(email)){
            navigate("/")
        }else{
            localStorage.setItem("cadastro_contador", JSON.stringify({
                email: email
            }))
        }
      }, [searchParams]);

    const onNext = (e) => {
        e?.preventDefault();
        if (email) {
            if (validarEmail(email)) {
                requestCoringas();
                return
            }
        } else {
        }
    }

    const requestCoringas = () => {

        setLoading(true)
        api.get("/mensageiro/get/dados/curingas", {
            params: {
                email: email
            }
        })
            .then(({ data }) => {
                if (data) {
                    localStorage.setItem("cadastro_contador", JSON.stringify({
                        email: email,
                        coringas: data
                    }))
                }
                navigate("/cadastro/contador/verificacao")
            })
            .catch(() => toast.error("Não foi possível buscar informações do usuário"))
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <a className="container-recovery" href="https://integracaohotline.com.br">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </a>
            </div>
            <div className="container-recovery p-4 w-100 flex content-center gap-10 wrap">
                <div style={{ maxWidth: 400 }}>
                    <h1 className="fw-600 fs-28 text-break">Cadastro contador <br /> Integra hotline</h1>
                </div>
                <div className="border-l p-4 w-100 br-2" style={{ maxWidth: 500 }}>
                    <ul className="w-100" style={{ padding: 0 }}>
                        <h5 className="t-em">
                            Para realizar o seu cadastro, siga os passos abaixo:
                            <ul>
                                <li className="t-em">
                                    <span className="t-em fw-600">1. Escolha o método de confirmação: </span>
                                    A revenda que lhe enviou o link de cadastro receberá um código de verificação. Você poderá escolher onde deseja que o código seja enviado:
                                    <ul>
                                        <li className="t-em fw-600">E-mail</li>
                                        <li className="t-em fw-600">WhatsApp</li>
                                        <li className="t-em fw-600">SMS</li>
                                    </ul>
                                    A revenda irá fornecer este código para você.
                                </li>

                                <li className="t-em">
                                    <span className="t-em fw-600">2. Receba o código da revenda: </span>
                                    Após escolher o método de confirmação, aguarde a revenda lhe enviar o código no canal escolhido.
                                </li>

                                <li className="t-em">
                                    <span className="t-em fw-600">3. Confirme o código: </span>
                                    Insira o código que recebeu da revenda para prosseguir com o processo de cadastro.
                                </li>

                                <li className="t-em">
                                    <span className="t-em fw-600">4. Preencha seus dados: </span>
                                    Após a confirmação do código, você será direcionado ao formulário de cadastro, onde deverá preencher todas as informações solicitadas.
                                </li>

                                <li className="t-em">
                                    <span className="t-em fw-600">5. Concorde com a política de privacidade: </span>
                                    Para finalizar o processo, leia e concorde com nossa política de privacidade.
                                </li>
                            </ul>
                            <p className="t-em">Depois de seguir todos esses passos, seu cadastro estará concluído!</p>
                        </h5>
                        <div>
                            <button
                                onClick={onNext}
                                disabled={loading}
                                className={`${loading ? 'p-2' : 'p-1'} mt-1 br-2 text-center pointer btn-email`}
                                style={{ backgroundColor: loading ? '#ababab' : firstColor, color: "white", minWidth: 180 }}>
                                {loading ? <Loading /> : "Continuar"}
                            </button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ConfirmAccountPage;