import axios from "axios";

export class ExternalService {
    static async cnpj(cnpj){
        return await axios.get(`https://comercial.cnpj.ws/cnpj/${cnpj}?token=rJr7ibUAQJrzBRIxGZumyWpveyvMzjA0mAjNXwDrpTs4`)
    }
    static async getCep(cep){
        return axios.get(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET'
        })
     }
} 