import axios from "axios";

const api = axios.create({
    baseURL: "https://common.integracaohotline.com.br/"
});

const apiRevenda = axios.create({
    baseURL: "https://revenda.integracaohotline.com.br/"
});

export {
    api,
    apiRevenda
}