import React from "react";
import './index.css';
import { InputMask } from '@react-input/mask';

const Input = (props) => {
    const { Icon, mask, replacement, EndIcon } = props;
    return (
        <div className={`input-group ${EndIcon ? '' : "border-radius-end"}`}>
            {Icon ? <span className="input-group-text"> {Icon} </span> : <></>}
            {
                mask ?
                    <InputMask
                        {...props}
                        mask={mask}
                        replacement={replacement}
                        className={`p-2 input-mail fullwidth`}
                    />
                    :
                    <input
                        {...props}
                        className={`p-2 input-mail fullwidth`}
                    />
            }
            {EndIcon ? <span className="input-group-text-end-icon"> {EndIcon} </span> : <></>}
        </div>
    );
}

export default Input;
