import { Navigate, Outlet, useRoutes } from "react-router-dom"
import RegisterPage from "../pages/register"
import HomePage from "../pages/home"
import RequestPasswordPage from "../pages/request-password"
import RequestPasswordEmailPage from "../pages/request-password/email"
import RequestPasswordCode from "../pages/request-password/code"
import ResetPasswordPage from "../pages/request-password/reset"
import RequestAccountantPage from "../pages/register-accountant"
import ConfirmAccountPage from "../pages/register-accountant/confirm"
import RequestAccountantCode from "../pages/register-accountant/code"
import RegisterAccoutant from "../pages/register-accountant/register"
const Routers = () => {
    return useRoutes([
        {
            path: '/',
            
            element:  <Outlet />,
            children: [
                {
                    path: '/*',
                    element: <Navigate to="/"/>
                },
                {
                    path: '/',
                    element: <HomePage/>
                },
                {
                    path: '/inscricao',
                    element: <RegisterPage/>
                },
                {
                    path: "recuperar-senha",
                    element: <RequestPasswordPage />
                },
                {
                    path: "recuperar-senha/email",
                    element: <RequestPasswordEmailPage />
                },
                {
                    path: "recuperar-senha/code",
                    element: <RequestPasswordCode />
                },
                {
                    path: "recuperar-senha/resetar",
                    element: <ResetPasswordPage />
                },
                {
                    path: "cadastro/contador",
                    element: <ConfirmAccountPage />
                },
                {
                    path: "cadastro/contador/verificacao",
                    element: <RequestAccountantPage />
                },
                {
                    path: "cadastro/contador/code",
                    element: <RequestAccountantCode />
                },
                {
                    path: "cadastro/contador/registro",
                    element: <RegisterAccoutant />
                },
            ]
        }
    ])
}

export default Routers;