import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineChat } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./_variants.css"
import "./recovery-pass.css"
import { api } from "../../services";
import Loading from "../../components/loading";
import { toast } from "react-toastify";

const firstColor = "#f17f38"

const RequestPasswordPage = () => {

    const navigate = useNavigate();

    const [dataRecovery, setDataRecovery] = useState({})
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getInfosRecovery = localStorage.getItem("recovery_password")
        if (!JSON.parse(getInfosRecovery)) {
            navigate("/recuperar-senha/email")
        } else {
            setDataRecovery(JSON.parse(getInfosRecovery))
        }
    }, [])

    const SendCode = (type) => {
        setLoading(true)
        api.post(`/mensageiro/enviar/codigo?email=${dataRecovery?.email}&tipo_envio=${type}`)
            .then(({ data }) => {
                if (data) {
                    localStorage.setItem("recovery_password", JSON.stringify({
                        ...dataRecovery,
                        code: type
                    }))
                    navigate("/recuperar-senha/code")
                }
            })
            .catch(() => toast.error("Não foi possível enviar código de confirmação"))
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <div className="container-recovery">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </div>
            </div>
            <div className="container-recovery p-4 w-100 flex content-center gap-5 wrap">
                <div style={{ maxWidth: 400 }}>
                    <span className="p-0 fs-14 fw-400" style={{ color: "#374151" }}>INÍCIO DE SESSÃO</span>
                    <h1 className="fw-600 fs-28" style={{ lineHeight: 1.2 }}>Escolha um método de verificação para continuar o processo de recuperação de senha.</h1>
                    <div>
                        <div className="border-l p-2 br-18 flex flex-row mt-2 items-center pointer" onClick={() => navigate("/recuperar-senha/email")}>
                            <button className="btn-icon p-2 br-rounded flex items-center content-center" style={{ backgroundColor: firstColor + 35, width: 40, height: 40 }}>
                                <FaRegUser size={22} color={firstColor} />
                            </button>
                            <div className="p-2">
                                <h4 className="fs-14 fw-300 mb-0" style={{ lineHeight: 1.0 }}>{dataRecovery?.email}</h4>
                                <button className="fs-12 btn p-0 fw-400" style={{ color: firstColor, border: 'none', lineHeight: 1.0 }}>trocar conta</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-l p-4 w-100 br-2 relative" style={{ maxWidth: 500, minHeight: 350 }}>
                   {loading && <div className="absolute" style={{ width: "100%", height: "100%", backgroundColor: "#00000050", top: 0, left: 0 }}>
                        <Loading size={45}/>
                    </div>}
                    <ul className="w-100 p-0" style={{ opacity: loading ? .5 : 1 }}>
                        {
                            !!dataRecovery?.coringas?.celular && <ButtonAction
                                title="SMS"
                                text={`Vamos enviar um código para o número de telefone ${dataRecovery?.coringas?.celular}.`}
                                onClick={() => SendCode("sms")}
                                icon={<MdOutlineChat size={40} color={firstColor} />} />
                        }
                        {
                            !!dataRecovery?.coringas?.whatsapp && <ButtonAction
                                title="WhatsApp"
                                text={`Vamos enviar um código através do WhatsApp para o número ${dataRecovery?.coringas?.whatsapp}.`}
                                onClick={() =>  SendCode("whatsapp")}
                                icon={<FaWhatsapp size={40} color={firstColor} />} />
                        }
                        {
                            !!dataRecovery?.coringas?.email && <ButtonAction
                                title="E-mail"
                                text={`Vamos enviar um código para o e-mail ${dataRecovery?.coringas?.email}.`}
                                onClick={() =>  SendCode("email")}
                                icon={<MdOutlineEmail size={40} color={firstColor} />} />
                        }


                    </ul>
                </div>
            </div>
        </div>
    )
}

const ButtonAction = (props) => {

    const { icon, title, text, onClick } = props;

    return (
        <li className="pointer p-4 items-center between flex hover br-2 " onClick={onClick}>
            <div>
                <div className="flex gap-3">
                    <button className="btn-icon p-2" style={{ backgroundColor: '#f17f3835' }}>
                        {icon}
                    </button>
                    <div>
                        <h3 className="fw-500 fs-16 mb-0">{title}</h3>
                        <div className="fw-300 fs-14" style={{ lineHeight: 1.2, color: "#374151", maxWidth: 320 }}>
                            {text}
                        </div>
                    </div>
                </div>
            </div>
            <MdArrowForwardIos size={22} color="#00000055" />
        </li>
    )

}

export default RequestPasswordPage;