import { useEffect, useState } from "react";
// import "./styles.css"
import axios from "axios";
import { toast } from "react-toastify";

import Loading from "../../components/loading"
import { useNavigate } from "react-router-dom";
import { api } from "../../services";
import { validarEmail } from "../../utils/validators";

const ResetPasswordPage = () => {

    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);

    const [dataRecovery, setDataRecovery] = useState({});

    const [errorPass, setErrorPass] = useState("");
    const [errorPass2, setErrorPass2] = useState("");

    useEffect(() => {
        const getInfosRecovery = localStorage.getItem("recovery_password")
        if (!JSON.parse(getInfosRecovery) || !JSON.parse(getInfosRecovery)?.token_uuid) {
            navigate("/recuperar-senha/email")
        } else {
            setDataRecovery(JSON.parse(getInfosRecovery))
        }
    }, []);

    const handleSubmit = (e) => {
        e?.preventDefault()
        if (!password) {
            setErrorPass("Por favor, Preencha este campo.")
            return
        }
        if (password.length < 4) {
            setErrorPass("Senha deve ter no mínimo 4 caracteres.")
            return
        }
        if (!confirmPassword) {
            setErrorPass2("Por favor, Preencha este campo.")
            return
        }
        if (password !== confirmPassword) {
            setErrorPass2("Os campos senhas devem ser iguais.")
            return
        }

        e.preventDefault();
        setLoading(true);
        api.put(`mensageiro/alterar/senha?token_uuid=${dataRecovery?.token_uuid}&nova_senha=${password}`)
            .then(() => {
                toast("Senha alterada com successo!", { type: 'success' })
                localStorage.removeItem("recovery_password")
                setTimeout(() => {
                    window.location.href = `https://cpanel.integracaohotline.com.br/login?e=${dataRecovery?.email}`
                }, 2500)
            })
            .catch((err) => {
                if (typeof err?.response?.data?.detail === "string") {
                    toast.error(err?.response?.data?.detail)
                    return
                }
                toast.error("Não foi possível alterar senha, Por favor tente novamente!")
            })
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <section className="min-vh-100 d-flex align-items-center overlay-soft-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className="mb-0 h3">Altere sua senha</h1>
                                </div>
                                <form onSubmit={handleSubmit} className="mt-4">
                                    <div className="form-group mb-4">
                                        <label for="email">Nova senha</label>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon2">
                                                <span class="material-symbols-outlined">
                                                    lock
                                                </span>
                                            </span>
                                            <input
                                                value={password}
                                                onChange={e => {
                                                    setPassword(e.target.value)
                                                    setErrorPass("")
                                                }}
                                                type={showPass ? "text" : "password"} placeholder=""
                                                className={`form-control ${errorPass ? 'input-error' : ''}`}
                                                id="password"
                                                required />
                                            <span className="input-group-text" id="basic-addon2">
                                                <span onClick={() => setShowPass(!showPass)} className="material-symbols-outlined pointer">
                                                    {!showPass ? "visibility" : "visibility_off"}
                                                </span>
                                            </span>
                                        </div>
                                        <p className="fs-12" style={{ color: "red" }}>{errorPass}</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group mb-4">
                                            <label for="password">Repita a nova senha</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span class="material-symbols-outlined">
                                                        lock
                                                    </span>
                                                </span>
                                                <input
                                                    value={confirmPassword}
                                                    onChange={e => {
                                                        setConfirmPassword(e.target.value)
                                                        setErrorPass2("")
                                                    }}
                                                    type={showPass2 ? "text" : "password"} placeholder=""
                                                    className={`form-control ${errorPass2 ? 'input-error' : ''}`}
                                                    id="password" required />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span onClick={() => setShowPass2(!showPass2)} className="material-symbols-outlined pointer">
                                                        {!showPass2 ? "visibility" : "visibility_off"}
                                                    </span>
                                                </span>
                                            </div>
                                        <p className="fs-12" style={{ color: "red" }}>{errorPass2}</p>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className={"btn " + (loading ? "btn-primary-app" : "btn-primary")}>
                                            {loading ? <Loading /> : "Confirmar"}
                                        </button>
                                    </div>
                                </form>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span className="fw-normal">

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPasswordPage;